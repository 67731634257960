<!-- 课件 -->
<template>
  <div class="cw_box">
    <div>
      <!-- <a href=""></a> -->
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="科目">
          <el-select v-model="form.subject" placeholder="请选择" size="mini">
            <el-option
              v-for="item in subject_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="课件名称">
          <el-input v-model="form.name" size="mini"></el-input>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        size="mini"
        style="margin-left: 10px"
        @click="click_text_btn(5)"
        >搜索</el-button
      >
      <el-button type="primary" size="mini" @click="click_text_btn(6)"
        >重置</el-button
      >
      <el-button
        type="primary"
        size="mini"
        v-fence="{ data: '', functions: ['上传'] }"
        @click="click_text_btn(4)"
        >上传</el-button
      >
    </div>
    <el-card class="cw_card">
      <el-scrollbar style="height: 100%">
        <el-empty
          description="暂无数据"
          v-if="tableData.length == 0"
        ></el-empty>
        <el-table
          v-if="tableData.length > 0"
          :data="tableData"
          border
          style="width: 100%"
          :header-cell-style="{
            background: '#F3F7FF',
            color: '#0B1B31',
            fontSize: '14px',
            fontWeight: '500',
          }"
        >
          <el-table-column
            prop="id"
            label="课件ID"
            width="200"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="coursewareName"
            label="课件名称"
            width="300"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="subjectName"
            label="科目"
            width="300"
            align="center"
          ></el-table-column>
          <el-table-column label="上传时间" align="center">
            <template slot-scope="scope">
              {{ currentTime.formatDate(scope.row.createTime * 1000) }}
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="type"
            label="是否共享"
            align="center"
            v-fence="{ data: '', functions: ['上传'] }"
          >
            <template slot-scope="scope">{{scope.row.type==1?'是':'否'}}</template>
          </el-table-column>-->
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                class="text_btn"
                @click="click_text_btn(0, scope.$index, scope.row)"
                v-fence="{ data: '', functions: ['上传'] }"
                >查看</el-button
              >
              <el-button
                type="text"
                class="text_btn"
                @click="click_text_btn(1, scope.$index, scope.row)"
                v-fence="{ data: '', functions: ['上传'] }"
                >编辑</el-button
              >
              <el-button
                type="text"
                class="text_btn"
                @click="click_text_btn(2, scope.$index, scope.row)"
                >下载</el-button
              >
              <el-button
                type="text"
                class="text_btn text_del_btn"
                @click="click_text_btn(3, scope.$index, scope.row)"
                v-fence="{ data: '', functions: ['上传'] }"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-scrollbar>
    </el-card>
    <el-pagination
      @current-change="handleCurrentChange"
      :page-size="page.pageSize"
      :current-page.sync="page.pageNum"
      layout="total, prev, pager, next"
      :total="page.total"
      background
      style="margin-top: 20px; text-align: center"
    ></el-pagination>

    <addCourseware
      :value="row"
      :dialogVisible="dialogVisible"
      :subject_options="subject_options"
      :type="type"
      @un_show="un_show"
    ></addCourseware>
  </div>
</template>

<script>
import addCourseware from "./components/add_courseware.vue";
import {
  subjectList,
  coursewareGetCoursewarePage,
  coursewareRemove,
} from "../../api/api";
export default {
  data() {
    return {
      form: {
        name: "",
        subject: "",
      },
      subject_options: [
        {
          value: "",
          label: "全部",
        },
      ],
      tableData: [],
      dialogVisible: false,
      row: "",
      page: {
        pageSize: 10,
        total: 100,
        pageNum: 1,
      },
      type: 0,
    };
  },

  components: {
    addCourseware,
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      subjectList({}).then((res) => {
        if (res.message == "请求成功") {
          res.data.list.map((i) => {
            this.subject_options.push({
              value: i.id,
              label: i.name,
            });
          });
        }
      });
      this.list();
    },
    list() {
      var obj = {
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        query: {
          subjectId: this.form.subject,
          coursewareName: this.form.name,
        },
      };
      coursewareGetCoursewarePage(obj).then((res) => {
        this.page.total = res.data.total;
        this.tableData = res.data.list;
      });
    },
    click_text_btn(type, index, row) {
      // 0 查看  1 编辑  2 下载  3 删除  4上传   5搜索
      if (type == 4) {
        this.type = 4;
        this.dialogVisible = true;
      }
      if (type == 0) {
        //查看
        this.row = row;
        this.type = 0;
        this.dialogVisible = true;
      } else if (type == 1) {
        //编辑
        this.row = row;
        this.type = 1;
        this.dialogVisible = true;
      } else if (type == 2) {
        console.log(row.fileList[0].url);
        var a = document.createElement("a");
        a.setAttribute("href", row.fileList[0].url);
        document.body.appendChild(a);
        a.click();
      } else if (type == 3) {
        //删除
        coursewareRemove({ id: row.id }).then((res) => {
          if (res.message == "请求成功") {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.list();
          }
        });
      } else if (type == 5) {
        this.page.pageNum = 1;
        this.list();
      } else if (type == 6) {
        this.form.subject = "";
        this.form.name = "";
        this.page.pageNum = 1;
        this.list();
      }
    },
    un_show(type) {
      this.dialogVisible = false;
      this.list();
    },
    //分页
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.list();
    },
  },
};
</script>
<style lang='less' scoped>
@import './style/index.less';
</style>
