<!-- 添加课件 -->
<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="科目">
          <el-select
            clearable
            :disabled="subject_value"
            v-model="ruleForm.subject_value"
            placeholder="请选择"
            size="mini"
          >
            <el-option
              v-for="item in subject_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专题">
          <el-select
            clearable
            v-model="ruleForm.special_value"
            placeholder="请选择"
            :disabled="special_value"
            size="mini"
          >
            <el-option
              v-for="item in special_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="变式">
          <el-select
            clearable
            :disabled="variant_value"
            v-model="ruleForm.variant_value"
            placeholder="请选择"
            size="mini"
          >
            <el-option
              v-for="item in variant_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="课件名称" prop="name">
          <el-input
            :disabled="subject_value"
            v-model="ruleForm.name"
            placeholder="请输入"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="文件上传" prop="fileList">
          <el-upload
            :disabled="subject_value"
            class="upload-demo"
            action
            :on-remove="handleRemove_fileList"
            :http-request="handleUpload_fileList"
            :before-upload="beforeAvatarUpload"
            multiple
            :limit="1"
            :file-list="ruleForm.fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png/word/视频等文件，且不超过10M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注" style="width: 100%">
          <el-input
            :disabled="subject_value"
            v-model="ruleForm.ramk"
            type="textarea"
            placeholder="请输入"
            size="mini"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('un_show', 0)">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { client } from "../../../components/AliOss";
import {
  coursewareCreateCourseware, //新增课件
  getThemeCaseNamePage, //通过专题查变式
  coursewareGetCoursewareById,
  coursewareModifyCourseware, //修改
  themeListThemeTitleBySubjectId, //通过科目查专题
} from "../../../api/api";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
    },
    value: {},
    type: {},
    subject_options: {},
  },
  data() {
    return {
      title: "shangchuan",
      ruleForm: {},
      special_value: true,
      variant_value: true,
      subject_value: false,
      rules: {
        subject_value: [
          { required: true, message: "请选择学科", trigger: "change" },
        ],
        special_value: [
          { required: true, message: "请选择专题", trigger: "change" },
        ],
        variant_value: [
          { required: true, message: "请选择变式", trigger: "change" },
        ],
        name: [{ required: true, message: "请输入课件名称", trigger: "blur" }],
        fileList: [
          { required: true, message: "请上传课件", trigger: "change" },
        ],
      },
      special_options: [],
      variant_options: [],
      istype: "",
      istypes: "",
    };
  },
  watch: {
    dialogVisible: {
      handler(newValue, oldValue) {
        if (newValue) {
          if (this.type == 4) {
            this.title = "上传课件";
            this.subject_value = false;
            this.ruleForm = {
              subject_value: "",
              special_value: "",
              variant_value: "",
              name: "",
              fileList: [],
              ramk: "",
            };
            return;
          }

          //编辑       //查看  value是数据
          coursewareGetCoursewareById({ id: this.value.id }).then((res) => {
            if (res.message == "请求成功") {
              this.ruleForm = {
                subject_value: res.data.subjectId,
                special_value: res.data.themeId,
                variant_value: res.data.caseId,
                name: res.data.coursewareName,
                fileList: res.data.fileList,
                ramk: res.data.remark,
              };
              if (this.type == 0) {
                this.subject_value = true;
                this.special_value = true;
                this.variant_value = true;
                this.title = "查看课件";
              } else if (this.type == 1) {
                this.title = "编辑课件";
                this.subject_value = false;
                this.special_value = false;
                this.variant_value = false;
              }
            }
          });
        } else {
        }
      },
    },
    "ruleForm.subject_value": {
      handler(newValue, oldValue) {
        if (newValue) {
          console.log("切换专题");
          if (this.istype == 1) {
            this.ruleForm.variant_value = "";
            this.ruleForm.special_value = "";
          }
          themeListThemeTitleBySubjectId({
            subjectId: newValue,
          }).then((res) => {
            console.log(res);
            if (res.message == "请求成功") {
              this.special_options = [];
              this.istype = 1;
              res.data.map((i) => {
                this.special_options.push({
                  value: i.themeId,
                  label: i.themeTitle,
                });
              });
              this.JudgeState();
              if (!this.JudgeState()) {
                this.special_value = false;
              }
            }
          });
        } else {
          this.special_value = true;
        }
      },
    },
    "ruleForm.special_value": {
      handler(newValue, oldValue) {
        if (newValue) {
          console.log(newValue, "切换变式");
          if (this.istypes == 1) {
            this.ruleForm.variant_value = "";
          }
          getThemeCaseNamePage({
            query: {
              themeId: newValue,
            },
          }).then((res) => {
            if (res.message == "请求成功") {
              this.istypes = 1;
              this.variant_options = [];
              // this.ruleForm.variant_value=''
              res.data.list.map((i) => {
                this.variant_options.push({
                  value: i.id,
                  label: i.caseName,
                });
              });
              this.JudgeState();
              if (!this.JudgeState()) {
                this.variant_value = false;
              }
            }
          });
        } else {
          this.variant_value = true;
        }
      },
    },
  },
  components: {},

  methods: {
    //判断状态
    JudgeState() {
      if (this.type == 0) {
        this.subject_value = true;
        this.special_value = true;
        this.variant_value = true;
        return true;
      } else if (this.type == 1) {
        this.subject_value = false;
        this.special_value = false;
        this.variant_value = false;
        return true;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var obj = {
            caseId: this.ruleForm.variant_value,
            coursewareName: this.ruleForm.name,
            fileList: [...this.ruleForm.fileList],
            remark: this.ruleForm.ramk,
            subjectId: this.ruleForm.subject_value,
            themeId: this.ruleForm.special_value,
          };
          console.log(this.value);
          if (this.type == 4) {
            //上传
            coursewareCreateCourseware(obj).then((res) => {
              if (res.message == "请求成功") {
                this.$message({
                  message: "上传成功",
                  type: "success",
                });
                this.$emit("un_show", 1);
              }
            });
          } else if (this.type == 0) {
            //0查看
            this.$emit("un_show", 1);
          } else if (this.type == 1) {
            obj["id"] = this.value.id;
            coursewareModifyCourseware(obj).then((res) => {
              if (res.message == "请求成功") {
                this.$message({
                  message: "编辑成功",
                  type: "success",
                });
                this.$emit("un_show", 1);
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 上传之前判断类型
    beforeAvatarUpload(file) {
      console.log(file.type);
      const isvideo = file.type === "video/mp4";
      const ispng = file.type === "image/png";
      const isjpg = file.type === "image/jpeg";
      const isdocx =
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/msword";

      const isLt500K = file.size / 1024 > 10240;
      let bl_a = true;
      if (!ispng && !isjpg && !isvideo && !isdocx) {
        bl_a = false;
        this.$message.error("只能上传jpg/png/word/视频文件！");
      }
      if (isLt500K) {
        this.$message.error("上传图片大小不能超过10M！");
      }
      return !isLt500K && bl_a;
    },
    // 上传文件
    handleUpload_fileList(file) {
      const that = this;
      const fileName = `${Date.parse(new Date())}-${file.file.name}`; //定义唯一的文件名
      client()
        .put("courseware/" + fileName, file.file)
        .then((res) => {
          let url = res.res.requestUrls[0].split("?")[0];
          that.ruleForm.fileList.push({
            name: file.file.name,
            url: url,
          });
          console.log(that.ruleForm.fileList);
        })
        .catch((err) => {
          console.log("上传失败");
        });
    },
    // 上传文件删除
    handleRemove_fileList(file, fileList) {
      let file_item = this.ruleForm.fileList.findIndex((find_item) => {
        return file == find_item;
      });
      this.ruleForm.fileList.splice(file_item, 1);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.$emit("un_show", 1);
        })
        .catch((_) => {});
    },
  },
};
</script>
<style lang='less' scoped>
/deep/.el-form-item {
  width: 50%;
  display: inline-block;
  .el-select {
    width: 100%;
  }
}
/deep/.el-textarea__inner {
  height: 100px;
  resize: none;
}
</style>
